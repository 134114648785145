import React, { useState } from 'react';
import i18n from '../Locales';
import ReactGA from 'react-ga';


import { DRAMATIME_URL, DT_PROJECT_ID, DT_SECRET_KEY, TELCO } from '../Constants';




const Login = (props) => {

    const [code, setcode] = useState("966")
    const [number, setnumber] = useState("");
    const [telco, settelco] = useState("zain");
    const [pin, setpin] = useState("");
    const [showPin, setshowPin] = useState(false);
    const [errMesg, seterrMsg] = useState("")
    const [btnText, setbtnText] = useState(i18n.continue)


    ReactGA.initialize('UA-128044800-1');


    const checkORCreateUser = async (telco, code, number) => {
        // Google events
        ReactGA.event({
            category: 'click',
            action: `${telco}-Send-Pin-Click`,
            label: `${telco}-Send-Pin-Click`,
            //value: phone
        });

        seterrMsg("")
        if (!number || ((number.trim().length) !== 9)) {
            seterrMsg(i18n.validnumber)
            setshowPin(false)
            setbtnText(i18n.continue)
            return
        }
        if (!telco) {
            seterrMsg(i18n.selectcarrier)
            setshowPin(false)
            setbtnText(i18n.continue)
            return
        }

        try {
            setbtnText(i18n.pleasewait)
            let url = `${DRAMATIME_URL}/api/user/get_user_status?msisdn=${code + number}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&web_user=1&telco=${telco}`
            let resp = await fetch(url)
            let respObj = await resp.json()

            console.log('user Status ->', respObj)

            if (respObj.status === 0) {
                url = `${DRAMATIME_URL}/api/user/app_start?web_user=1&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&telco=${telco}&phone_no=${code + number}`
                resp = await fetch(url)
                respObj = await resp.json()
            }

            console.log("app start ->", respObj)
            if (respObj.status === 1 && respObj.user.subscribe_status === 1) {
                props.setUser(respObj.user, true)
                setbtnText(i18n.continue)
            } else if (respObj.status === 1 && respObj.user.subscribe_status === 3) {
                seterrMsg(i18n.insuficientbalancemsg)
                setbtnText(i18n.continue)

            } else {
                sendPin(telco, code, number)
            }

        } catch (e) {
            console.log("Error in check user status", e.message);
            seterrMsg(i18n.somethingwrongmsg)
            setbtnText(i18n.continue)
        }

    }

    const sendPin = async (telco, code, number) => {

        localStorage.setItem('telco', telco)

        seterrMsg("")
        try {

            const url = `${DRAMATIME_URL}/telco/${telco}/send_pin?web_user=1&telco=${telco}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&msisdn=${code + number}`
            let resp = await fetch(url)
            let respObj = await resp.json()
            console.log('Send pin resp ->', respObj)
            if (respObj.status === 1) {
                setshowPin(true)
                setbtnText(i18n.subscribe)
                // Google events
                ReactGA.event({
                    category: 'click',
                    action: `${telco}-Send-Pin-Success`,
                    label: `${telco}-Send-Pin-Success`,
                    //value: phone
                });
            }
            else {
                seterrMsg(i18n.sendpinerrmesg)
                setshowPin(false)
                setbtnText(i18n.continue)
                // Google events
                ReactGA.event({
                    category: 'click',
                    action: `${telco}-Send-Pin-Failed`,
                    label: `${telco}-Send-Pin-Failed`,
                    //value: phone
                });
            }

        } catch (e) {
            console.log("Error in send pin", e.message);
            seterrMsg(i18n.somethingwrongmsg)
            setbtnText(i18n.continue)
            // Google events
            ReactGA.event({
                category: 'click',
                action: `${telco}-Send-Pin-Server-Error`,
                label: `${telco}-Send-Pin-Server-Error`,
                //value: phone
            });
        }

    }

    const confirmPin = async (telco, code, number, pin) => {

        seterrMsg("")
        if (!pin) {
            seterrMsg(i18n.enterpinmsg)
            setshowPin(true)
            setbtnText(i18n.subscribe)
            return
        }
        try {

            // Google events
            ReactGA.event({
                category: 'click',
                action: `${telco}-Confirm-Pin-Click`,
                label: `${telco}-Confirm-Pin-Click`,
                //value: phone + '-' + pin
            });

            setbtnText(i18n.pleasewait)

            const url = `${DRAMATIME_URL}/telco/${telco}/confirm_pin_n_subscribe?web_user=1&telco=${telco}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&msisdn=${code + number}&pin=${pin}`
            let resp = await fetch(url)
            let respObj = await resp.json()
            console.log('confirm pin resp', respObj)
            if (respObj.status === 1) {
                // Google events
                ReactGA.event({
                    category: 'click',
                    action: `${telco}-Confirm-Pin-Success`,
                    label: `${telco}-Confirm-Pin-Success`,
                    //value: phone + '-' + pin
                });
                setTimeout(function () {
                    props.setUser(respObj.user, true)
                }, 2000)
            }
            else {
                seterrMsg(i18n.confirmpinerrmsg)
                setshowPin(true)
                // Google events
                ReactGA.event({
                    category: 'click',
                    action: `${telco}-Confirm-Pin-Invalid`,
                    label: `${telco}-Confirm-Pin-Invalid`,
                    //value: phone + '-' + pin
                });
            }

            setbtnText(i18n.subscribe)

        } catch (e) {
            console.log("Error in confirm pin", e.message);
            seterrMsg(i18n.somethingwrongmsg)
            setbtnText(i18n.subscribe)
            ReactGA.event({
                category: 'click',
                action: `${telco}-Confirm-Pin-Server-Error`,
                label: `${telco}-Confirm-Pin-Server-Error`,
                //value: phone + '-' + pin
              });
        }

    }

    return (
        <div>
            <div className="" style={{ maxWidth: '100%', width: '100%', margin: '0 auto -3rem' }} >
                <div className="bg-top">
                    <div className="bg-bottom">
                        <div style={{ maxWidth: '400px', width: '80%', margin: '0 auto' }}>
                            {
                                !showPin && <form>
                                    <div className="row" >
                                        <div className="col">
                                            <h5 className="subscription-title">{i18n.subscription}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <img src="/img-subscription.png" alt='' className="subscription-img" />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-xs-2 formGridState">
                                            <img src="/sa.png" width="30" alt='' style={{ paddingTop: '16px' }} />
                                        </div>
                                        <div className="col-xs-2 formGridState">
                                            <select className="dt-select" onChange={e => { setcode(e.target.value) }}>
                                                <option value='966' defaultValue>966</option>
                                            </select>
                                        </div>
                                        <div className="col formGridAddress1 pl-0">
                                            <input className="form-control" placeholder={i18n.mobilenumber} maxLength="9" onChange={e => { setnumber(e.target.value) }} />
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgba(134,140,154,0.6)', width: '100%', marginBottom: '20px' }}></div>
                                    </div>
                                    <div className="row">
                                        <div className=" col form-group carrier pr-0">
                                            <select className="dt-select w-100" onChange={e => { settelco(e.target.value) }}>
                                                <option value="zain"> Zain </option>
                                                <option value="stc"> Stc </option>
                                                <option value="mobily"> Mobily </option>
                                            </select>
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgba(134,140,154,0.6)', width: '100%', marginBottom: '20px' }}></div>
                                    </div>
                                    {telco === 'zain' && <div className="row">
                                        <div className="col">
                                            <p className="text-center"> {i18n.zainpricepoints} </p>
                                        </div>
                                    </div>}
                                    {telco === 'mobily' && <div className="row">
                                        <div className="col">
                                            <p className="text-center"> {i18n.stcpricepoints} </p>
                                        </div>
                                    </div>}
                                    {telco === 'stc' && <div className="row">
                                        <div className="col">
                                            <p className="text-center"> {i18n.mobilypricepoints} </p>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col">
                                            <button variant="primary" type="submit" onClick={(e) => {
                                                e.preventDefault();
                                                checkORCreateUser(telco, code, number);
                                            }}
                                                className='btn-next btn btn-primary'>
                                                {btnText}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            }

                            {
                                showPin && <form>
                                    <div className="row" >
                                        <div className="col">
                                            <h5 className="subscription-title">{i18n.subscription}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <img src="/img-subscription.png" alt='' className="subscription-img" />
                                        </div>

                                    </div>
                                    <div className="row" >
                                        <div className="col ">
                                            <p> {i18n.sendpininfo} </p>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col formGridAddress1">
                                            <input
                                                style={{ width: '100%' }}
                                                className="form-control"
                                                placeholder={i18n.enterpin}
                                                onChange={e => { setpin(e.target.value) }}
                                            />
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgba(134,140,154,0.6)', width: '100%', marginBottom: '20px' }}></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button variant="primary" type="submit" onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    confirmPin(telco, code, number, pin);
                                                }}
                                                className='btn-next btn btn-primary'>
                                                {btnText}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center mt-5">
                                            <p> {i18n.dontrecievepin} </p>
                                            <h6 onClick={() => { setshowPin(false); setbtnText(i18n.continue) }} className="text-light cursor-pointer"> {i18n.resendpin} <i className="dt-chevron-right"></i></h6>
                                        </div>
                                    </div>
                                </form>
                            }
                            <div className="text-center mt-3 text-danger text-bold">{errMesg}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Login;