// export const KCMS_URL = "http://solutionsmodo.com";
// export const KCMS_SECRET_KEY = "214ce0a3922d5c0febdf587f1d9552";
// export const KCMS_PROJECT_ID = "11"

// export const DRAMATIME_URL = "http://dramatime.khaleef.com";
// export const DT_PROJECT_ID = "2";
// export const DT_SECRET_KEY = "93d7797ec6a47e97350f0f2276742c";

export const DRAMATIME_URL = "http://dramatime.khaleef.com";
export const DT_PROJECT_ID = "1";
export const DT_SECRET_KEY = "01b6f865d0bff7e7a438a5f76220ca";

export const KCMS_URL = "http://solutionsmodo.com";
export const KCMS_PROJECT_ID = "7"
export const KCMS_SECRET_KEY = "e53dd96aa1ea426221ba488af0809c"



export const PLAYER_SCRIPT_URL = 'https://cdn.jwplayer.com/libraries/Gu7DBc3s.js'

//export const PLAYER_SCRIPT_URL = 'JWPlayer.js'


//Old Script url used in JAZZ Cricket
//export const PLAYER_SCRIPT_URL = 'https://content.jwplatform.com/libraries/IDzF9Zmk.js'


export const TELCO = "zain" 