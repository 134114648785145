import React from 'react';

const Loader = (props) => {
    return(
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
} 

export default Loader;